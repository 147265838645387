<nav
  class="navbar navbar-expand-lg bg-body-tertiary sticky-top"
  style="background-color: #bebfc5"
>
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
      <img
        src="/VoteBlue2024HorizontalBlue.jpg"
        style="width: 9em"
        alt="Vote Blue 2024"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="toggleNavbar = !toggleNavbar"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse flex-row-reverse"
      id="navbarSupportedContent"
      [ngbCollapse]="toggleNavbar"
    >
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngIf="volunteer">
          <a class="nav-link" style="color: #25408f">{{
            volunteer.firstname
          }}</a>
        </li>
        <li class="nav-item" *ngIf="campaignService.calls">
          <a
            class="nav-link"
            *ngIf="campaignService.calls == 1"
            style="color: #25408f"
            >{{ campaignService.calls }} call made</a
          >
          <a
            class="nav-link"
            *ngIf="campaignService.calls > 1"
            style="color: #25408f"
            >{{ campaignService.calls }} calls made</a
          >
        </li>
        <li class="nav-item" *ngIf="volunteer">
          <button
            id="logoutButton"
            type="button"
            (click)="logout()"
            class="btn btn-primary"
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div
  class="d-flex flex-column min-vh-100 justify-content-center align-items-center"
  *ngIf="!volunteer"
>
  <div class="flex-shrink-0">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Phonebank Tool</h3>
        <div *ngIf="campaigns.length > 0; else noCampaigns">
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div class="row p-3">
              <div *ngFor="let campaignRow of campaigns" class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="campaignSelector"
                  name="campaignSelector"
                  id="campaign-{{ campaignRow.id }}"
                  value="{{ campaignRow.id }}"
                  checked
                />
                <label
                  class="form-check-label"
                  for="campaign-{{ campaignRow.id }}"
                >
                  {{ campaignRow.name }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <label for="firstname" class="form-label"
                  >Your first name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="firstname"
                  formControlName="firstname"
                  required
                />
              </div>
              <div class="col mb-3">
                <label for="lastname" class="form-label">Your last name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastname"
                  formControlName="lastname"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <label for="phone" class="form-label">Your phone</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  formControlName="phone"
                />
              </div>
              <div class="col mb-3">
                <label for="email" class="form-label">Your email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  required
                />
              </div>
            </div>
            <div class="row p-2">
              <div>
                <span
                  *ngIf="trySubmit && userForm.invalid"
                  class="badge badge-pill bg-danger mb-3"
                  >Please complete all fields.</span
                >
              </div>
              <button type="submit" class="btn btn-primary">Next</button>
            </div>
          </form>
        </div>
        <ng-template #noCampaigns
          ><p>No active phonebanks right now. Come back later!</p></ng-template
        >
      </div>
    </div>
  </div>
</div>
<div
  class="d-flex flex-column min-vh-100 justify-content-center align-items-center"
  *ngIf="volunteer && campaign"
>
  <div class="card" class="flex-shrink-0">
    <app-script [volunteer]="volunteer" [campaign]="campaign"></app-script>
  </div>
</div>
