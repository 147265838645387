import { Injectable, inject } from '@angular/core';
import { Firestore, collection, query, where, getDocs, doc, getDoc } from '@angular/fire/firestore';
import { Campaign } from './types'


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private firestore: Firestore = inject(Firestore);

  private campaignRoot: string = '/Phonebank/campaigns/campaigns/';
  public calls: number | undefined = undefined;

  constructor() {
  }

  ngOnInit() {
  }

  getAllCampaigns(): Promise<Campaign[]> {
    const collectionRef = collection(this.firestore, this.campaignRoot);
    const q = query(collectionRef, where("status", "==", "active"));
    return getDocs(q).then((querySnapshot: any) => {
      const campaigns: Campaign[] = [];
      querySnapshot.forEach((doc: any) => {
        campaigns.push(doc.data() as Campaign);
      });
      return campaigns;
    });
  }
}
